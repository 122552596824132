<template>
  <div>
    <el-dialog
      title="编辑记客数"
      :visible.sync="dialogTableVisible"
      width="20%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="formRules"
        :model="formData"
        :rules="formRules"
        label-width="70px"
      >
        <el-form-item label="记客数" prop="calcClient">
          <el-select v-model="formData.calcClient" placeholder="请选择记客数">
            <el-option
              v-for="item in calcClientList"
              :key="item.Value"
              :value="item.Value"
              :label="item.Name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog('formRules')">取 消</el-button>
        <el-button
          ref="submitButton"
          type="primary"
          @click="submitForm('formRules')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/cashier.js";
export default {
  props: {
    calcClientList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogTableVisible: false,
      formData: {
        billGuid: "",
        softwareDate: "",
        calcClient: "",
        recordType: "",
      },
      formRules: {
        calcClient: [
          { required: true, message: "请选择记客数", trigger: "change" },
        ],
      },
    };
  },

  methods: {
    onCloseDialog(formName) {
      this.$refs[formName].resetFields();
      this.dialogTableVisible = false;
      this.$emit("CloseDialog");
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$refs.submitButton.disabled = await true;
          let { data, errcode, errmsg } = await api.updateCalcClient(
            this.formData
          );
          if (errcode == (await 0)) {
            await this.$message({
              message: "修改成功!",
              type: "success",
            });
            await this.onCloseDialog("formRules");
          } else {
            await this.$message.error(errmsg);
          }
          this.$refs.submitButton.disabled = await false;
        }
      });
    },

    openCalcClient(data = {}) {
      let { BillNote, BillGuid, SoftwareDate, RecordType } = data;
      this.formData.billGuid = BillGuid;
      this.formData.recordType = RecordType;
      this.formData.softwareDate = this.util.dateFormat(
        "YYYY-mm-dd",
        new Date(data.SoftwareDate * 1000)
      );
      this.dialogTableVisible = true;
    },

    closeCalcClient() {
      this.dialogTableVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
</style>