import { render, staticRenderFns } from "./editor-software-date.vue?vue&type=template&id=f12e272a&scoped=true&"
import script from "./editor-software-date.vue?vue&type=script&lang=js&"
export * from "./editor-software-date.vue?vue&type=script&lang=js&"
import style0 from "./editor-software-date.vue?vue&type=style&index=0&id=f12e272a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f12e272a",
  null
  
)

export default component.exports