<template>
  <div>
    <el-dialog
      title="修改记账日期"
      :visible.sync="dialogTableVisible"
      width="20%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="formRules"
        :model="formData"
        :rules="formRules"
        label-width="80px"
      >
        <el-form-item label="记账日期" prop="newSoftwareDate">
          <el-date-picker
            v-model="formData.newSoftwareDate"
            size="small"
            type="date"
            align="left"
            unlink-panels
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            @change="onSelectDate"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog('formRules')">取 消</el-button>
        <el-button
          ref="submitButton"
          type="primary"
          @click="submitForm('formRules')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../../../api/cashier.js";
export default {
  data() {
    return {
      dialogTableVisible: false,
      formData: {
        newSoftwareDate: "",
      },
      formRules: {
        newSoftwareDate: [
          {
            type: "string",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
      },
      moreSoftwareDateArray: [],
    };
  },
  methods: {
    onCloseDialog(formName) {
      this.$refs[formName].resetFields();
      this.dialogTableVisible = false;
      this.$emit("CloseDialog");
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let tArray = [];
          this.$refs.submitButton.disabled = await true;
          await this.moreSoftwareDateArray.forEach((item) => {
            tArray.push({
              BillGuid: item.BillGuid,
              SoftwareDate: this.util.dateFormat(
                "YYYY-mm-dd",
                new Date(item.SoftwareDate * 1000)
              ),
              recordType: item.RecordType,
            });
          });

          let submitData = await {
            selectors: tArray,
            softwareDate: this.formData.newSoftwareDate,
          };
          let { data, errcode, errmsg } = await api.alterSoftwareSate(
            submitData
          );
          if (errcode == (await 0)) {
            await this.onCloseDialog("formRules");
            await this.$message({
              message: "修改成功!",
              type: "success",
            });
          } else {
            await this.$message.error(errmsg);
          }
          this.$refs.submitButton.disabled = await false;
        } else {
          return false;
        }
      });
    },

    openSoftwareDate(arr = []) {
      this.moreSoftwareDateArray = arr;
      this.dialogTableVisible = true;
    },

    closeSoftwareDate() {
      this.dialogTableVisible = false;
    },

    onSelectDate(event) {},
  },
};
</script>

<style lang="less" scoped>
.el-input {
  width: 100% !important;
}
</style>